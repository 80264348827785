// Tabs.tsx
import React, { useState, ReactNode } from "react";
import styled from "styled-components";
import { Flex } from "./Flex";
import { COLORS } from "./Theme";
interface TabButtonProps {
  active: boolean;
}

const TabButton = styled.button<TabButtonProps>`
  padding: 10px 20px;
  border: none;
  border-bottom: ${(props) =>
    props.active ? `2px solid ${COLORS.primary}` : "none"};
  color: black;
  background-color: transparent;

  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  cursor: pointer;
  outline: none;
  font-size: 16px;
  transition: all 0.3s;

  &:hover:active {
    color: ${COLORS.primary50};
  }
`;

// Props for Tabs Component
interface TabsProps {
  children: ReactNode;
}

// Tabs Component
export const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <Flex column gap="16px">
      <Flex>
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return (
              <TabButton
                key={index}
                active={index === activeIndex}
                onClick={() => handleTabClick(index)}
              >
                {child.props.title}
              </TabButton>
            );
          }
          return null;
        })}
      </Flex>

      {React.Children.map(children, (child, index) =>
        index === activeIndex && React.isValidElement(child) ? (
          <>{child.props.children}</>
        ) : null
      )}
    </Flex>
  );
};

// Props for Tab Component
interface TabProps {
  title: string;
}

// Tab Component (Child)
export const Tab = styled.div<TabProps>``;
