import React, { useEffect, useState } from "react";
import { Shift } from "../types/shifts";
import { fetchShifts, parseShiftData } from "../utils/shiftsUtils";
import { ShiftCard } from "./ShiftCard";
import { Flex } from "../elements/Flex";
import { Select } from "../elements/Select";
import { Tab, Tabs } from "../elements/Tabs";

export const Shifts = () => {
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [myNexShifts, setMyNextShifts] = useState<Shift[]>([]);
  const [nextShifts, setNextShifts] = useState<Shift[]>([]);
  const [currentShifts, setCurrentShifts] = useState<Shift[]>([]);
  const [selectedPerson, setSelectedPerson] = useState<string>(
    localStorage.getItem("person") || ""
  );
  const [persons, setPersons] = useState<string[]>([]);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [, setClickCount] = useState(0);

  useEffect(() => {
    setSelectedPerson(localStorage.getItem("person") || "");
  }, []);

  useEffect(() => {
    // Fetch and parse shifts
    fetchShifts().then((data) => {
      const parsedData = parseShiftData(data);
      setShifts(parsedData.sort((a, b) => a.startTime - b.startTime));
    });

    // Update current time every minute
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (shifts.length) {
      const uniquePersons = Array.from(
        new Set(shifts.map((shift) => shift.personnel))
      );
      setPersons(uniquePersons.filter((p) => !!p));
      // Find the current and next shifts
      const now = new Date(currentTime.getTime());
      const personNextShifts = shifts.filter(
        (s) =>
          selectedPerson &&
          s.startTime > now.getTime() &&
          s.personnel.includes(selectedPerson)
      );
      setMyNextShifts(personNextShifts);

      const current = shifts.filter(
        (shift) =>
          shift.startTime < now.getTime() && shift.endTime > now.getTime()
      );
      setCurrentShifts(current);

      setNextShifts(shifts.filter((s) => s.startTime > now.getTime()));
    }
  }, [shifts, currentTime, selectedPerson]);

  const handleClick = () => {
    setClickCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount >= 5) {
        window.location.assign("/justice");
      }
      return newCount;
    });
  };

  return (
    <Flex column>
      <h1>תורנויות</h1>

      <Flex justify="start" align="center" w100>
        <h3>השמירות הבאות שלי</h3>
      </Flex>
      <Flex align="center" gap="4px">
        <div
          style={{
            width: "120px",
          }}
        >
          סנן לפי שומר
        </div>
        <Select
          key={persons.length}
          options={persons}
          onChange={(selected) => {
            setSelectedPerson(selected);
            localStorage.setItem("person", selected);
          }}
          value={selectedPerson}
          style={{
            width: "calc(100% - 137px)",
          }}
        />
      </Flex>
      <Tabs>
        <Tab title="השמירות שלי">
          {myNexShifts.length > 0 ? (
            <Flex column gap="8px">
              {myNexShifts.map((shift, idx) => (
                <ShiftCard
                  key={`my-${idx}`}
                  shift={shift}
                  showTimeToShift={idx === 0}
                />
              ))}
            </Flex>
          ) : (
            <p>לא נבחר שומר</p>
          )}
        </Tab>
        <Tab title="בדיוק נטחנים">
          {currentShifts.length > 0 ? (
            <Flex column gap="8px" w100>
              {currentShifts.map((shift, idx) => (
                <ShiftCard key={`current-${idx}`} shift={shift} showRedBlink />
              ))}
            </Flex>
          ) : (
            <p>לא שומרים יותר</p>
          )}
        </Tab>
        <Tab title="הבאים בתור">
          {nextShifts.length > 0 ? (
            <Flex column gap="8px" w100>
              {nextShifts.map((shift, idx) => (
                <ShiftCard key={`next-${idx}`} shift={shift} />
              ))}
            </Flex>
          ) : (
            <p>לא שומרים יותר</p>
          )}
        </Tab>
      </Tabs>

      <div
        onClick={handleClick}
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "transparent",
          padding: "10px",
          height: "50px",
          width: "100%",
        }}
      ></div>
    </Flex>
  );
};
