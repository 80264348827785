import styled from "styled-components";
import { COLORS } from "./Theme";

export const Input = styled.input`
  padding: 16px 12px;
  border-radius: 24px;
  font-size: 18px;
  border: 1px solid ${COLORS.primary};
  width: 100%;
`;
