import React, { useState, useCallback, useEffect } from "react";
import Tesseract from "tesseract.js";
import { Camera } from "./Camera";
import { AccessAuthorizedModal } from "./AccessAuthorizedModal";
import { NoMatchModal } from "./NoMatchModal";
import {
  findBestMatch,
  findBestMatchesFromList,
} from "../utils/numberDetection";
import { ResultsTable } from "./ResultsTable";
import { Flex } from "../elements/Flex";
import { MainButton, TextButton } from "../elements/Buttons";
import { Input } from "../elements/Input";
import { Visitor } from "../types/visitors";

const GOOGLE_SHEET_BASE_URL =
  "https://docs.google.com/spreadsheets/d/1nttwiGczcn4WxAAdzCngZVmumJA7E1xosOSFgkHXV-c/gviz/tq?tqx=out:json";

export const EmanuelGate: React.FC = () => {
  const [plateNumber, setPlateNumber] = useState<string | null>(null);
  const [modalDetails, setModalDetails] = useState<Visitor | null>(null);
  const [searchDetails, setSearchDetails] = useState<Visitor[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNoMatchModalOpen, setIsNoMatchModalOpen] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [lastCapturedFrame, setLastCapturedFrame] = useState<string | null>(
    null
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [visitors, setVisitors] = useState<Visitor[]>([]);
  const [freeText, setFreeText] = React.useState("");
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchAllRows = async () => {
      const tabGids = ["0", "1831322190"];
      const allVisitors: Visitor[] = [];

      for (const gid of tabGids) {
        const rows = await fetchRowsFromTab(gid, true);
        if (rows) allVisitors.push(...rows);
      }
      const unauthorizedTabs = ["480076216"];
      for (const gid of unauthorizedTabs) {
        const rows = await fetchRowsFromTab(gid, false);
        if (rows) allVisitors.push(...rows);
      }
      setVisitors(allVisitors);
    };

    fetchAllRows(); // Initial fetch

    // Set up interval to update `refresh` every 7 minutes
    const intervalId = setInterval(() => {
      setRefresh((prev) => prev + 1);
    }, 7 * 60 * 1000);

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [refresh]);

  const fetchRowsFromTab = async (
    gid: string,
    isAuthorized: boolean
  ): Promise<Visitor[] | null> => {
    try {
      const response = await fetch(`${GOOGLE_SHEET_BASE_URL}&gid=${gid}`);
      const textData = await response.text();
      console.log(`Fetched data from tab with gid ${gid}`);
      const jsonData = JSON.parse(textData.substr(47).slice(0, -2));
      const visitors: Visitor[] = jsonData.table.rows.map((row: any) => ({
        stickerNum: row.c[0]?.v,
        stickerType: row.c[1]?.v,
        personalNumber: row.c[2]?.v,
        rank: row.c[3]?.v,
        firstName: row.c[4]?.v,
        lastName: row.c[5]?.v,
        phone: row.c[6]?.v,
        licensePlate: row.c[7]?.v,
        notes: row.c[8]?.v,
        isAuthorized,
      }));
      return visitors;
    } catch (error) {
      console.error(`Error fetching data from tab with gid ${gid}:`, error);
      return null;
    }
  };

  const handleCapture = useCallback((dataUrl: string) => {
    setLastCapturedFrame(dataUrl); // Store the last frame
  }, []);

  const startOcr = async () => {
    if (lastCapturedFrame) {
      console.log("Starting OCR on the captured frame");
      setPlateNumber(null);
      setIsProcessing(true);

      const ocrStartTime = performance.now();
      const detectedPlate = await retryOcr(lastCapturedFrame, 3);
      const ocrEndTime = performance.now();

      console.log(
        `OCR completed in ${(ocrEndTime - ocrStartTime).toFixed(
          2
        )} ms, detect: ${detectedPlate}`
      );
      setIsProcessing(false);
      setPlateNumber(detectedPlate);

      if (detectedPlate) {
        searchAcrossTabs(detectedPlate);
      } else {
        setIsNoMatchModalOpen(true);
      }
    } else {
      console.warn("No frame captured yet.");
    }
  };

  const retryOcr = async (
    dataUrl: string,
    retries: number
  ): Promise<string | null> => {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        console.log(`OCR attempt ${attempt}`);
        return await detectLicensePlate(dataUrl);
      } catch (error) {
        console.error(`OCR attempt ${attempt} failed.`, error);
        if (attempt === retries) return null;
      }
    }
    return null;
  };

  const detectLicensePlate = async (
    dataUrl: string
  ): Promise<string | null> => {
    const {
      data: { text },
    } = await Tesseract.recognize(dataUrl, "eng");
    console.log("Detected text:", text);

    const platePattern = /\b\d{2,}\b/g;
    const matches = text.match(platePattern);
    const concatenatedNumbers = matches ? matches.join("") : null;
    console.log("Concatenated plate number:", concatenatedNumbers);

    return concatenatedNumbers?.length && concatenatedNumbers.length > 4
      ? concatenatedNumbers
      : null;
  };

  const searchAcrossTabs = async (plateNumber: string): Promise<boolean> => {
    const bestMatchIndex = findBestMatch(
      plateNumber,
      visitors.map((row) => Object.values(row).join(" ").toLocaleLowerCase())
    );
    if (bestMatchIndex !== -1) {
      const row = visitors[bestMatchIndex];
      console.log("Found match!", plateNumber, row);
      setModalDetails(row);
      setIsModalOpen(true);
      return true;
    } else {
      setIsNoMatchModalOpen(true);
    }
    return false;
  };

  const handleFreeTextSearch = (inputText: string) => {
    setFreeText(inputText);

    const foundRowsIndex = findBestMatchesFromList(
      inputText.split(" "),
      visitors.map((row) => Object.values(row).join(" ").toLocaleLowerCase())
    );
    setSearchDetails(
      visitors.filter((_, index) => foundRowsIndex.includes(index))
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalDetails(null);
  };

  const closeNoMatchModal = () => {
    setIsNoMatchModalOpen(false);
    setSearchDetails(null);
    setPlateNumber(null);
  };

  const handleCameraToggle = () => {
    setIsCameraOn((prev) => !prev);
  };

  return (
    <Flex
      column={true}
      justify="center"
      align="center"
      w100={true}
      style={{
        textAlign: "center",
      }}
    >
      <Flex
        column={true}
        justify="center"
        align="center"
        gap="10px"
        padding="10px"
      >
        <Flex w100 align="center" gap="4px">
          <Input
            type="text"
            placeholder="חיפוש חופשי"
            value={freeText}
            onChange={(e) => handleFreeTextSearch(e.target.value)}
            ref={(input) => {
              if (input) input.focus();
            }}
          />
          {freeText && (
            <MainButton
              variant="primary"
              onClick={() => {
                setFreeText("");
                const input = document.querySelector('input[type="text"]');
                if (input) (input as HTMLInputElement).focus();
              }}
            >
              נקה
            </MainButton>
          )}
        </Flex>
        <div
          style={{
            fontSize: "0.8rem",
            color: "#777",
            textAlign: "center",
          }}
        >
          מחפש על הכל, שמות, מספר רכב, מ"א, הכל תופס, תתפרע
        </div>
        {searchDetails && searchDetails.length > 0 && (
          <ResultsTable details={searchDetails} searchText={freeText} />
        )}
        <TextButton onClick={() => setRefresh((prev) => prev + 1)}>
          רענן את הרשימה
        </TextButton>
      </Flex>
      {!isCameraOn && <h5>למה לעבוד קשה</h5>}

      <MainButton
        onClick={handleCameraToggle}
        variant={isCameraOn ? "secondary" : "primary"}
      >
        {isCameraOn ? "סגור מצלמה" : "פתח מצלמה"}
      </MainButton>

      {isCameraOn && (
        <div style={{ position: "relative" }}>
          {isProcessing && lastCapturedFrame ? (
            <img
              src={lastCapturedFrame}
              alt="OCR Frame"
              style={{
                width: "auto",
                height: "300px",
              }}
            />
          ) : (
            <Camera onCapture={handleCapture} />
          )}

          {isProcessing && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: "10px",
                borderRadius: "50%",
              }}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>⏳</span>
            </div>
          )}
        </div>
      )}

      {isCameraOn && !isProcessing && (
        <MainButton onClick={startOcr} variant={"primary"}>
          סרוק ת'לוחית רישוי
        </MainButton>
      )}

      {isCameraOn && isProcessing && <p>סבלנות אחי, בודק שניה</p>}
      {isModalOpen && (
        <AccessAuthorizedModal onClose={closeModal} details={modalDetails} />
      )}

      {isNoMatchModalOpen && (
        <NoMatchModal onClose={closeNoMatchModal} plateNumber={plateNumber} />
      )}
    </Flex>
  );
};
