export const COLORS = {
  primary: "#5664F5",
  primary50: "#c2c8fc",
  secondary: "#1b2151",
  secondary50: "#36394f",
  danger: "rgb(217, 4, 4)",
  danger800: "rgb(140, 3, 3)",
  gray: "#adadad",
  gray50: "#f9f9f9",
  gray800: "#6d6d6d",
  green: "#83f785",
};
