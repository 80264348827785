export type Visitor = {
  stickerNum?: string;
  stickerType?: string;
  personalNumber?: string;
  rank?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  licensePlate?: string;
  notes?: string;
  isAuthorized: boolean;
};

export const visitFieldName = {
  stickerNum: "מספר מדבקה",
  stickerType: "סוג מדבקה",
  personalNumber: "מספר אישי",
  rank: "דרגה",
  firstName: "שם פרטי",
  lastName: "שם משפחה",
  phone: "טלפון",
  licensePlate: "מספר רישוי",
  notes: "הערות",
  isAuthorized: "מאושר",
};
