import React from "react";
import { Flex } from "../elements/Flex";
import { COLORS } from "../elements/Theme";
import { visitFieldName, Visitor } from "../types/visitors";

type Props = {
  details: Visitor[];
  searchText?: string;
};

// Helper to highlight "searchText" within "text"
const highlightText = (text?: string, searchText?: string) => {
  if (!searchText) return text;

  // Build regex that captures all occurrences (case-insensitive)
  const regex = new RegExp(`(${searchText})`, "gi");

  // Split text around the search text, wrap matches in <mark>
  return text
    ?.split(regex)
    ?.map((part, idx) =>
      part?.toLowerCase() === searchText?.toLowerCase() ? (
        <mark key={idx}>{part}</mark>
      ) : (
        part
      )
    );
};

export const ResultsTable = ({ details, searchText }: Props) => {
  const searchParts = searchText?.split(" ");
  console.log("searchParts", searchParts);

  return (
    <div
      style={{
        overflowY: "auto",
        height: "100%",
        width: "100%",
        maxHeight: "400px",
        padding: "16px 8px",
        borderRadius: "12px",
        backgroundColor: COLORS.gray50,
      }}
    >
      {details.map((personData, index) => (
        <Flex
          column
          justify="start"
          align="start"
          style={
            personData.isAuthorized
              ? {}
              : {
                  borderColor: COLORS.danger800,
                  borderRadius: "12px",
                  borderWidth: "4px",
                  borderStyle: "solid",
                  padding: "8px",
                }
          }
          key={index}
        >
          {!personData.isAuthorized && (
            <div
              style={{
                top: "15px",
                left: "20px",
                color: COLORS.danger,
                backgroundColor: COLORS.gray50,
                fontSize: "1.4rem",
                fontWeight: 800,
                borderRadius: "8px",
              }}
            >
              לא מורשה!
            </div>
          )}
          {Object.entries(personData)
            .filter(([k, v]) => v !== undefined && k !== "isAuthorized")
            .map(([k, v], j) => (
              <Flex justify="between" align="center" gap="8px" key={j}>
                <div>{visitFieldName[k as keyof Visitor]}:</div>
                <b>
                  {highlightText(
                    v?.toString(),
                    searchParts?.find((s) =>
                      v?.toString()?.toLowerCase()?.includes(s?.toLowerCase())
                    )
                  )}
                </b>
              </Flex>
            ))}
          <div>------------------------------</div>
        </Flex>
      ))}
    </div>
  );
};
